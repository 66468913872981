import { Button, colors, ResponsiveValue } from "@biblioteksentralen/react";
import { MouseEventHandler, ReactNode } from "react";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  left?: ResponsiveValue<string>;
  right?: ResponsiveValue<string>;
};

const GalleryNavigationButton = ({ onClick, children, left, right }: Props) => (
  <Button
    position="absolute"
    left={left}
    right={right}
    top="50%"
    transform="translateY(-50%)"
    onClick={onClick}
    color={colors.black}
    background={colors.grey05}
    fontSize="lg"
    _hover={{ background: colors.grey15 }}
    size="sm"
    paddingRight="0.5rem"
    zIndex={1}
  >
    {children}
  </Button>
);

export default GalleryNavigationButton;
