import { Button, Icon, Link } from "@biblioteksentralen/react";
import { LinkButton } from "@libry-content/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { PropsWithChildren } from "react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { isUrlInternal } from "../../utils/isUrlInternal";
import { useCommonData } from "../layout/CommonDataProvider";
import { ExternalLink } from "react-feather";

type Props = PropsWithChildren<PortableTextTypeComponentProps<LinkButton>>;

export function TextLinkButtonSerializer({ value }: Props) {
  const { ts } = useTranslation();
  const isInternal = isUrlInternal(useCommonData().site, value.url);
  if (!ts(value?.label) || !value?.url) return null;

  return (
    <Link href={value.url} display="block" textAlign="center" marginBottom="2rem">
      <Button variant="secondary">
        {ts(value.label)}
        {!isInternal && <Icon as={ExternalLink} margin="0 0 1px 0.5rem" top="0.1rem" position="relative" size="1em" />}
      </Button>
    </Link>
  );
}
