import { createContext, PropsWithChildren, useContext } from "react";

type Context = {
  logInternalLinks?: boolean;
  galleryBackgroundColors?: Record<string, string>;
};

const initialValues: Context = {
  logInternalLinks: false,
};

const BlockContentContext = createContext(initialValues);

export const BlockContentContextProvider = (props: PropsWithChildren<Context>) => (
  <BlockContentContext.Provider value={props}>{props.children}</BlockContentContext.Provider>
);

export const useBlockContentContext = () => useContext(BlockContentContext);
