import { ArrayElement, ExtraRichTextBlock, RichTextBlock } from "@libry-content/types";

type Style = "normal" | "h2" | "h3";

export type StyleMap = Partial<Record<Style, Style>>;

type Block = ArrayElement<RichTextBlock | ExtraRichTextBlock>;

const blockHasStyle = (block?: Block): block is Block & { style: Style } =>
  typeof (block as any)?.["style"] === "string";

export const mapStyles = (styleMap?: StyleMap, blocks?: Block[]): NonNullable<Block[]> =>
  blocks?.map((block: Block) =>
    styleMap && blockHasStyle(block) && block.style in styleMap ? { ...block, style: styleMap[block.style] } : block
  ) ?? [];
