import { colors, Icon, Link, LinkProps } from "@biblioteksentralen/react";
import { PortableTextMarkComponent } from "@portabletext/react";
import { logClick } from "../../utils/analytics/Plausible";
import { isUrlInternal } from "../../utils/isUrlInternal";
import { useCommonData } from "../layout/CommonDataProvider";
import { useBlockContentContext } from "./BlockContentContext";
import { ExternalLink } from "react-feather";

export const CommonLinkStyling = ({
  href,
  children,
  accentColor = colors.accentBlueMedium,
  hoverColor = colors.accentBlueMedium,
  ...linkProps
}: LinkProps & { accentColor?: string; hoverColor?: string }) => {
  const isInternal = useIsInternal(href);

  return (
    <Link
      textDecoration="underline"
      textDecorationColor={accentColor}
      textUnderlineOffset="0.375rem"
      _hover={{ color: hoverColor, textDecorationColor: hoverColor, svg: { color: hoverColor } }}
      transition="all 0.25s ease"
      href={href}
      // as={isInternal ? LocalizedNextLink : undefined} //TODO: fiks denne
      alignItems="center"
      gap=".3rem"
      {...linkProps}
    >
      {children}
      {!isInternal && (
        <Icon
          as={ExternalLink}
          margin="0 0.1rem 0 0.25rem"
          top="0.1rem"
          position="relative"
          size="1em"
          color={accentColor}
        />
      )}
    </Link>
  );
};

export const LinkSerializer: PortableTextMarkComponent<{ href?: string; _type: "link" }> = (props) => {
  const { logInternalLinks } = useBlockContentContext();
  const href = props.value?.href;
  const isInternal = useIsInternal(href);

  if (!href) return <>{props.children}</>;

  const logInternalClick = () => {
    if (isInternal && logInternalLinks) {
      logClick(`Riktekst: intern lenke ${href}`, { riktekstInternLenke: href });
    }
  };

  return (
    <CommonLinkStyling href={href} onClick={logInternalClick}>
      {props.children}
    </CommonLinkStyling>
  );
};

const useIsInternal = (href: string | undefined) => isUrlInternal(useCommonData().site, href);
