import { Box, ResponsiveValue } from "@biblioteksentralen/react";
import { ReactNode } from "react";
import ReactMasonryCSS from "react-masonry-css";
import useBreakpointValue from "../../utils/hooks/useBreakpointValue";

/*
There are some options to create masonry ourselves using css only but as far as
I could see they have drawbacks:
- column-count: gives column order instead of row order
- flex: need to set explicit height
*/

const className = "masonry-wrapper";

const columnClassName = "masonry-column";

const overrideMasonryStyles = (gap: string) => ({
  [`.${className}`]: {
    display: "flex",
    width: "100%",
  },
  [`.${columnClassName}`]: {
    paddingLeft: gap,
    backgroundClip: "padding-box",
    "&:first-child": {
      paddingLeft: 0,
    },
    /* Individual items */
    "> *": {
      marginBottom: gap,
    },
  },
});

type Props = {
  children: ReactNode;
  nColumns?: ResponsiveValue<number>;
  gap?: ResponsiveValue<string>;
};

const Masonry = ({ children, nColumns = 3, gap = "1rem" }: Props) => {
  const responsiveNColumns = useBreakpointValue<number>(nColumns);
  const responsiveGap = useBreakpointValue<string>(gap);

  return (
    <>
      <Box sx={overrideMasonryStyles(responsiveGap)}>
        <ReactMasonryCSS breakpointCols={responsiveNColumns} className={className} columnClassName={columnClassName}>
          {children}
        </ReactMasonryCSS>
      </Box>
    </>
  );
};

export default Masonry;
